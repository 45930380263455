const epochs = [
    ['tahun', 31536000],
    ['bulan', 2592000],
    ['minggu', 604800],
    ['hari', 86400],
    ['jam', 3600],
    ['menit', 60],
    ['kedua', 1],
]

const getDuration = (timeAgoInSeconds) => {
    for (let [name, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds)
        if (interval >= 1) {
            return {
                interval: interval,
                epoch: name,
            }
        }
    }
}

const timeAgo = (date) => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000)
    const { interval, epoch } = getDuration(timeAgoInSeconds)
    return `${interval} ${epoch} lalu`
}
export default timeAgo
