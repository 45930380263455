import {
    CatalogDescription,
    CatalogImage,
    CatalogTitle,
    Container,
    NoDataContainer,
    PodcastDetailContainer,
} from './styles'
import { Fragment } from 'react'

export const NoData = ({ height, width, img, title, msgs }) => {
    return (
        <Fragment>
            <Container>
                <NoDataContainer>
                    <PodcastDetailContainer>
                        <CatalogImage
                            loading="lazy"
                            src={img}
                            height={height ? height : '100px'}
                            width={width ? width : '100px'}
                        />

                        <CatalogTitle>{title}</CatalogTitle>
                        {msgs.map((msg, index) => {
                            return (
                                <CatalogDescription key={index}>
                                    {msg}
                                </CatalogDescription>
                            )
                        })}
                    </PodcastDetailContainer>
                </NoDataContainer>
            </Container>
        </Fragment>
    )
}
