import colors from '@/themes/colors'
import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    ${ColumnFlex}
`
export const ItemContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-auto-flow: row;
    grid-gap: 15px;
    justify-content: space-around;
    padding: 0 20px;
    width: 100%;
    margin-top: ${(props) => `${props.page === 'live' ? 'unset' : '50'}px`};
    margin-bottom: ${(props) => `${props.page === 'live' ? 'unset' : '50%'}`};
    @media (min-width: 760px) {
        margin-bottom: ${(props) =>
            `${props.page === 'live' ? 'unset' : '20%'}`};
    }
`

export const HorizontalContainer = styled.div`
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
`

export const CardContainer = styled.div`
    ${ColumnFlex}
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
`

export const HorizontalCardContainer = styled.div`
    ${RowFlex}
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
`

export const CardImage = styled.img`
    border-radius: 15px;
    width: 100%;
    // height: auto;
    cursor: pointer;
    /* height: 75%; */
`
export const AudioBookCardImageContainer = styled.div`
    width: 100%;
    background-image: ${({ background }) => `url('${background}')`};
    background-size: cover;
    height: ${({ height }) => `${height}px`};
    border-radius: 15px;
    backdrop-filter: blur(4px);
`

export const HorizontalCardImage = styled.img`
    border-radius: 15px;
    width: 20%;
    // height: auto;
    cursor: pointer;
    margin-right: 10px;
    /* height: 75%; */
`

export const CardContent = styled.div`
    // margin-left: 15px;
    margin-right: auto;
    padding-right: 10px;
    cursor: pointer;
    line-height: 19px;
`

export const HorizontalCardContent = styled.div`
    // margin-left: 15px;
    flex-grow: 1;
    margin-right: auto;
    padding-right: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    ::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #252525;
        bottom: -5px;
    }
`

export const CardSource = styled.span`
    display: block;
    color: #fad810;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.05em;
`

export const CardTitle = styled.a`
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 0 5px;
    letter-spacing: 0.03em;
    line-height: 22px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
export const CardAuthor = styled.span`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #707070;
`
export const Header = styled.div`
    ${RowFlex}
    padding: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    // max-width: 698px;
    background-color: ${colors.black};
    align-items: center;
    z-index: 999;
    @media (min-width: 992px) {
        width: 700px;
    }
`
export const BackButton = styled.img`
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
`
export const HeaderText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #fafafa;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const SearchImage = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 3vw;
    cursor: pointer;
`

export const ShareImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
`
export const CardSubTitle = styled.div`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #707070;
    &::after {
        font-size: 10px;
        letter-spacing: 0.03em;
        color: #707070;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
export const CardTime = styled.div`
    font-size: 10px;
    letter-spacing: 0.03em;
    color: #707070;
    &::after {
        font-size: 10px;
        letter-spacing: 0.03em;
        color: #707070;
    }
`

export const SpeakerBox = styled.div`
    width: 100%;
    height: ${(props) => `${props.height}px`};
    border-radius: 8px;
    position: relative;
    background: #4a4a4a;
    // margin-top: 50px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 20px;
`

export const PrimaryAvatar = styled.div`
    width: 80px;
    height: 80px;
    background: url('${(props) => props.photo || '/img/default-user.svg'}');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 48px;
    position: absolute;
    left: ${(props) =>
        props.total === 1 ? '36%' : props.total === 2 ? '15%' : '15%'};
    top: 30%;
    z-index: 2;
    @media (max-width: 812px) {
        width: 50px;
        height: 50px;
        left: ${(props) =>
            props.total === 1 ? '30%' : props.total === 2 ? '8%' : '10%'};
        top: 30%;
    }
`

export const SecondaryAvatar = styled.div`
    width: ${(props) => (props.total === 2 ? '80px' : '50px')};
    height: ${(props) => (props.total === 2 ? '80px' : '50px')};
    background: url('${(props) => props.photo || '/img/default-user.svg'}');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 48px;
    position: absolute;
    top: ${(props) =>
        props.order === 1 ? (props?.total === 2 ? '30%' : '30%') : '39%'};
    left: ${(props) =>
        props.order === 1 ? (props?.total === 2 ? '57%' : '57%') : '65%'};
    z-index: 2;
    @media (max-width: 812px) {
        width: ${(props) => (props.total === 2 ? '50px' : '30px')};
        height: ${(props) => (props.total === 2 ? '50px' : '30px')};
        top: ${(props) =>
            props.order === 1 ? (props?.total === 2 ? '30%' : '30%') : '41%'};
        left: ${(props) =>
            props.order === 1 ? (props?.total === 2 ? '52%' : '55%') : '63%'};
    }
`

export const ParticipantAvatar = styled.div`
    width: 55px;
    height: 55px;
    background: url('${(props) => props.photo || '/img/default-user.svg'}');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 48px;
    position: relative;
`

export const Participants = styled.div`
    width: 328px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px 20px;
    margin-bottom: 150px;
`

export const ParticipantName = styled.span`
    color: ${(props) => (props.isActive ? '#4285F4' : 'white')};
    position: absolute;
    bottom: -18px;
    font-size: 10px;
    width: 36px;
    white-space: nowrap;
    font-weight: 400;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`
export const Username = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    /* identical to box height, or 14px */

    text-align: center;

    color: ${(props) => (props.isVerified ? '#4285F4' : '#ffffff')};
    white-space: nowrap;
    // width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 1px solid #000000;
`
export const VerifyIconImage = styled.img`
    margin-left: ${(props) => (props.livestream ? '2px' : 'unset')};
`
export const SpeakerBackground = styled.div`
    position: absolute;
    filter: blur(4px) brightness(0.3);
    background: url('${(props) => props.photo || '/img/default-user.svg'}');
    background-size: cover;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
`
export const SpeakerBoxContainer = styled.div`
    ${RowFlex}
    justify-content:space-between;
`
export const SpeakerBoxTitle = styled.span`
    position: absolute;
    top: 10px;
    padding: 5px 10px;
    color: white;
    z-index: 3;
    font-size: 12px;
    display: flex;
    gap: 10px;
`
export const VerifyBadge = styled.img`
    float: ${(props) => (props.livestream ? 'none' : 'left')};
    margin-right: ${(props) => (props.livestream ? '2px' : 'unset')};
`

export const LiveLogoContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    border: 1px solid #fad810;
    border-radius: 8px;
    width: 38px;
    height: 18px;
`
export const LiveText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #f9cf5f;
`
export const ListenContainer = styled.span`
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 2;
`
export const ListenIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-right: 5px;
`

export const ListenText = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 70%;
    color: rgba(250, 250, 250, 0.7);
`
