import { ColumnFlex, FlexCentered } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    overflow-y: auto;
    width: 100%;
`

export const NoDataContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    background: ${(props) => `url(${props.imageURL})`}
    no-repeat;
    background-size: cover;
    padding: 4.44%;
    width: 100%;
    // height: 300px;
    background-position: center center;
    overflow: auto;
    position: relative;
    // margin-bottom: 50%;
    @media (max-width: 990px) {
        margin-bottom: 20%;
    }
`

export const PodcastDetailContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered} 
    z-index: 1;
    width: 100%;
`
export const CatalogImage = styled.img`
    border-radius: 8px;
`

export const CatalogTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    margin: 1.25vh 0;
`

export const CatalogInfo = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #fafafa;
    margin-top: 1.25vh;
`

export const CatalogDescription = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;

    color: rgba(255, 255, 255, 0.8);
    // margin-top: 1.25vh;
`
