import { NoData } from './NoData'
import {
    Container,
    UserInfoContainer,
    FollowButtonAndShareContainer,
    FollowButton,
    FollowButtonInnerContainer,
    FollowIcon,
    FollowLabel,
    HeaderContainer,
    TabsContainer,
    ListItems,
    Item,
    ItemContainer,
    ShowMoreButtonContainer,
    ShowMoreButtonText,
    ShowMoreButton,
    ActivityContainer,
    ActivityBox,
    ActivityComment,
    ActivityCommentHeader,
    Avatar,
    CommentInfoContainer,
    CommentInfoInnerContainer,
    CommentUserName,
    DisplaynameAndTimeContainer,
    DisplayUserName,
    TimeCointainer,
    CommentTime,
    Commentar,
    ActivityCommentByUser,
    ContentContainer,
    ContentInformationContainer,
    ContentInformationImage,
    ContentTypeTitleAndFollowers,
    ContentTitleText,
    ContentFollowersText,
    AnotherFollowButton,
    FollowButtonIcon,
    FollowButtonText,
    FullUserInfoContainer,
    FullUserInfoRightContainer,
    FullUserInfoLeftContainer,
    FullUserInfoImage,
    FullUserNameAndDisplayNameContainer,
    FullDisplayName,
    FullUserName,
    FullUserInfoNumberContainer,
    FullUserInfoFollowerContainer,
    FullUserInfoFollowersInnerContainer,
    FullUserInfoNumberText,
    FullUserInfoTagText,
    FullUserInfoBioContainer,
    FullUserInfoBio,
    FullUserInfoEditButtonContainer,
    FullUserInfoEditButtonInner,
    FullUserInfoEditButton,
    FullUserInfoEditButtonDetails,
    FullUserInfoEditButtonImage,
    FullUserInfoEditButtonText,
    UserProfileAndNumberContainer,
    UserInfoAndBioContainer,
    UserEditButtonContainer,
    UserProfileImageContainer,
    UserProfileImage,
    UserProfileNumberContainer,
    UserProfileFollowerContainer,
    UserProfileNumber,
    UserProfileTag,
    UserInfoDisplayName,
    UserInfoUserName,
    UserInfoBio,
    UserEditButtonInnerContainer,
    UserEditButtonInfo,
    UserEditButtonImage,
    UserEditButtonText,
    CommentInfoNameContainer,
    Langganan,
    ItemText,
    Voucher,
} from './styles'
import { EpisodeInformationPage } from '@/components/EpisodeInformationPage'
import VerticalCardItem from '@/components/Home/SegmentDetail/VerticalCardItem'
import compressedImage from '@/helpers/compressedImage'
import { compressedUserPhoto } from '@/helpers/compressedUserPhoto'
import NumberConversion from '@/helpers/numberConversion'
import SecondsToTime from '@/helpers/secondsToTime'
import timeAgo from '@/helpers/timeSince'
import UnicodeToChar from '@/helpers/unicodeToChar'
import { setShowDownloadPrompt } from '@/store/actions/player'
import {
    fetchUserProfileStatus,
    initializeUserProfilePage,
    updateProfileTab,
} from '@/store/actions/user'
import * as userSelector from '@/store/selectors/user'
import { PROFILETABTYPES } from '@/store/types/user'
import { getUserProfileData } from '@/utils/api-ssr'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const UserProfileDetails = ({ userId }) => {
    const { data: session } = useSession()
    const history = useRouter()
    const dispatch = useDispatch()
    const limit = 10

    const navElement = useRef(false)
    const [isSticky, setIsSticky] = useState(false)
    const [navTopOffset, setNavTopOffset] = useState(0)
    const [page, setPage] = useState(1)
    const userDetails = useSelector(userSelector._userDetails)
    const userCatalogs = useSelector(userSelector._userCatalogs)
    const userActivities = useSelector(userSelector._userActivities)
    // const userProfileStatus = useSelector(userSelector._fetchUserProfileStatus)
    const totalUserCatalogCount = useSelector(
        userSelector._totalUserCatalogCount
    )
    const totalUserActivitiesCount = useSelector(
        userSelector._totalUserActivitiesCount
    )

    const activeProfileTab = useSelector(userSelector._activeProfileTab)

    const isLoggedInUser = session?.userInfo?.id === userDetails?.id

    useEffect(() => {
        if (
            userDetails &&
            userDetails?.isCreator &&
            userCatalogs &&
            userCatalogs?.length > 0
        ) {
            dispatch(updateProfileTab(PROFILETABTYPES.KARYA))
        } else {
            dispatch(updateProfileTab(PROFILETABTYPES.AKTIFITAS))
        }
    }, [userDetails, userCatalogs, dispatch])

    useEffect(() => {
        const getUserProfilePage = async (query) => {
            dispatch(fetchUserProfileStatus('fetching'))
            try {
                const data = await getUserProfileData(query, session?.token)

                dispatch(initializeUserProfilePage(data))
                dispatch(fetchUserProfileStatus('success'))
            } catch (error) {
                dispatch(fetchUserProfileStatus('failure'))
                console.log('error', error)
            }
        }

        if (page > 1) {
            const request = {
                userId,
                page,
                limit,
                includeEntities: `["userActions", "user", "live"]`,
                aggregations: 'listeningTime|followers|followees',
                profileUserId: userId,
            }
            getUserProfilePage(request)
        }
    }, [page, userId, dispatch, session])

    useEffect(() => {
        const handleSticky = () => {
            navTopOffset < window.scrollY - 100
                ? setIsSticky(true)
                : setIsSticky(false)
        }

        setNavTopOffset(navElement?.current?.offsetTop)
        window.addEventListener('scroll', handleSticky)

        return () => {
            window.removeEventListener('scroll', handleSticky)
        }
    }, [navTopOffset])

    const fetchMore = () => {
        setPage((prevPage) => prevPage + 1)
    }

    // useEffect(() => {
    //     let target = document.querySelector('#header')
    //     document.addEventListener('scroll', () => {
    //         if (
    //             target &&
    //             window.scrollY >= target.getBoundingClientRect().top
    //         ) {
    //             setHeaderName(true)
    //         } else {
    //             setHeaderName(false)
    //         }
    //     })
    // }, [])

    return (
        <Fragment>
            <Container>
                {/* <HeaderContainer> */}
                {/* <LoginHeader /> */}
                <HeaderContainer imageUrl={userDetails?.photo}>
                    <UserInfoContainer>
                        <UserProfileAndNumberContainer>
                            <UserProfileImageContainer>
                                <UserProfileImage>
                                    <img
                                        alt="user photo"
                                        src={compressedUserPhoto(userDetails)}
                                        width="100"
                                        height="100"
                                        loading="lazy"
                                    />
                                </UserProfileImage>
                            </UserProfileImageContainer>
                            <UserProfileNumberContainer>
                                <Link
                                    href={`/followers/${userDetails?.id}`}
                                    prefetch={false}
                                >
                                    <a>
                                        <UserProfileFollowerContainer>
                                            <UserProfileNumber>
                                                {userDetails?.aggregation
                                                    ?.followers || 0}
                                            </UserProfileNumber>
                                            <UserProfileTag>
                                                Followers
                                            </UserProfileTag>
                                        </UserProfileFollowerContainer>
                                    </a>
                                </Link>
                                <Link
                                    href={`/following/${userDetails?.id}`}
                                    prefetch={false}
                                >
                                    <a>
                                        <UserProfileFollowerContainer>
                                            <UserProfileNumber>
                                                {userDetails?.aggregation
                                                    ?.followees || 0}
                                            </UserProfileNumber>
                                            <UserProfileTag>
                                                Following
                                            </UserProfileTag>
                                        </UserProfileFollowerContainer>
                                    </a>
                                </Link>
                                <UserProfileFollowerContainer>
                                    <UserProfileNumber>
                                        {SecondsToTime(
                                            userDetails?.aggregation
                                                ?.listeningTime,
                                            'hours'
                                        ) || 0}
                                    </UserProfileNumber>
                                    <UserProfileTag>Mendengar</UserProfileTag>
                                </UserProfileFollowerContainer>
                            </UserProfileNumberContainer>
                        </UserProfileAndNumberContainer>
                        <UserInfoAndBioContainer>
                            <UserInfoDisplayName>
                                {userDetails?.displayName}
                            </UserInfoDisplayName>
                            <UserInfoUserName>
                                {`@${userDetails?.userName}`}
                            </UserInfoUserName>
                            <UserInfoBio>{userDetails?.bio}</UserInfoBio>
                        </UserInfoAndBioContainer>
                        {/* <FullUserInfoEditButtonContainer>
                                <FullUserInfoEditButtonInner>
                                    <FullUserInfoEditButton>
                                        <FullUserInfoEditButtonDetails>
                                            <FullUserInfoEditButtonImage src="/img/Android.svg" />
                                            <FullUserInfoEditButtonText>
                                                Ubah profil
                                            </FullUserInfoEditButtonText>
                                        </FullUserInfoEditButtonDetails>
                                    </FullUserInfoEditButton>
                                </FullUserInfoEditButtonInner>
                            </FullUserInfoEditButtonContainer> */}

                        {!isLoggedInUser && (
                            <FollowButtonAndShareContainer>
                                <FollowButton
                                    onClick={() =>
                                        dispatch(setShowDownloadPrompt(true))
                                    }
                                >
                                    <FollowButtonInnerContainer>
                                        <FollowIcon src="/img/primary-follow-icon.svg" />
                                        <FollowLabel>Follow</FollowLabel>
                                    </FollowButtonInnerContainer>
                                </FollowButton>
                                {/* <ShareContainer
                                        onClick={() =>
                                            dispatch(
                                                setShowDownloadPrompt(true)
                                            )
                                        }
                                    >
                                        <ShareIcon src="/img/share-icon.svg" />
                                    </ShareContainer> */}
                            </FollowButtonAndShareContainer>
                        )}
                        {isLoggedInUser && (
                            <Link href="/edit-profile" prefetch={false}>
                                <a style={{ width: '100%' }}>
                                    <UserEditButtonContainer>
                                        <UserEditButtonInnerContainer>
                                            <UserEditButtonInfo>
                                                <UserEditButtonImage src="/img/edit-alt.svg" />
                                                <UserEditButtonText>
                                                    Ubah profil
                                                </UserEditButtonText>
                                            </UserEditButtonInfo>
                                        </UserEditButtonInnerContainer>
                                    </UserEditButtonContainer>
                                </a>
                            </Link>
                        )}

                        <Langganan href="/subscription">
                            <img alt="subscription" src="/img/subscribe.svg" />
                            <span>Langganan</span>
                        </Langganan>

                        <Voucher href="/voucher/redeem">
                            <img
                                alt="subscription"
                                src="/img/tukar_voucher.svg"
                            />
                            <span>Tukar voucher</span>
                        </Voucher>
                    </UserInfoContainer>
                </HeaderContainer>
                <FullUserInfoContainer imageUrl={userDetails?.photo}>
                    <FullUserInfoRightContainer>
                        <FullUserNameAndDisplayNameContainer>
                            <FullDisplayName>
                                {userDetails?.displayName}
                            </FullDisplayName>
                            <FullUserName>
                                {`@${userDetails?.userName}`}
                                <img
                                    alt="noice maker"
                                    src={'/img/noicemaker.svg'}
                                    loading="lazy"
                                />
                                {userDetails?.isSubscribed && (
                                    <img
                                        alt="system badge"
                                        src={'/img/system_badge.svg'}
                                        loading="lazy"
                                    />
                                )}
                            </FullUserName>
                        </FullUserNameAndDisplayNameContainer>
                        <FullUserInfoNumberContainer>
                            <Link
                                href={`/followers/${userDetails?.id}`}
                                prefetch={false}
                            >
                                <a>
                                    <FullUserInfoFollowerContainer>
                                        <FullUserInfoFollowersInnerContainer>
                                            <FullUserInfoNumberText>
                                                {userDetails?.aggregation
                                                    ?.followers || 0}
                                            </FullUserInfoNumberText>
                                            <FullUserInfoTagText>
                                                Followers
                                            </FullUserInfoTagText>
                                        </FullUserInfoFollowersInnerContainer>
                                    </FullUserInfoFollowerContainer>
                                </a>
                            </Link>
                            <Link
                                href={`/following/${userDetails?.id}`}
                                prefetch={false}
                            >
                                <a>
                                    <FullUserInfoFollowerContainer>
                                        <FullUserInfoFollowersInnerContainer>
                                            <FullUserInfoNumberText>
                                                {userDetails?.aggregation
                                                    ?.followees || 0}
                                            </FullUserInfoNumberText>
                                            <FullUserInfoTagText>
                                                Following
                                            </FullUserInfoTagText>
                                        </FullUserInfoFollowersInnerContainer>
                                    </FullUserInfoFollowerContainer>
                                </a>
                            </Link>
                            <a>
                                <FullUserInfoFollowerContainer>
                                    <FullUserInfoFollowersInnerContainer>
                                        <FullUserInfoNumberText>
                                            {SecondsToTime(
                                                userDetails?.aggregation
                                                    ?.listeningTime,
                                                'hours'
                                            ) || 0}
                                        </FullUserInfoNumberText>
                                        <FullUserInfoTagText text={'Mendengar'}>
                                            Mendengar
                                        </FullUserInfoTagText>
                                    </FullUserInfoFollowersInnerContainer>
                                </FullUserInfoFollowerContainer>
                            </a>
                        </FullUserInfoNumberContainer>
                        <FullUserInfoBioContainer>
                            <FullUserInfoBio>
                                {userDetails?.bio}
                            </FullUserInfoBio>
                        </FullUserInfoBioContainer>
                        {!isLoggedInUser ? (
                            userDetails?.meta?.userActions?.length ? (
                                <FullUserInfoEditButtonContainer
                                    onClick={() =>
                                        dispatch(setShowDownloadPrompt(true))
                                    }
                                >
                                    <FullUserInfoEditButtonInner>
                                        <FullUserInfoEditButton>
                                            <FullUserInfoEditButtonDetails>
                                                {/* <FullUserInfoEditButtonImage src="/img/following.svg" /> */}
                                                <FullUserInfoEditButtonText>
                                                    Following
                                                </FullUserInfoEditButtonText>
                                            </FullUserInfoEditButtonDetails>
                                        </FullUserInfoEditButton>
                                    </FullUserInfoEditButtonInner>
                                </FullUserInfoEditButtonContainer>
                            ) : (
                                <FullUserInfoEditButtonContainer>
                                    <FollowButton
                                        onClick={() =>
                                            dispatch(
                                                setShowDownloadPrompt(true)
                                            )
                                        }
                                    >
                                        <FollowButtonInnerContainer>
                                            <FollowIcon src="/img/primary-follow-icon.svg" />
                                            <FollowLabel>Follow</FollowLabel>
                                        </FollowButtonInnerContainer>
                                    </FollowButton>
                                </FullUserInfoEditButtonContainer>
                            )
                        ) : (
                            ''
                        )}
                        {/* {!isLoggedInUser && (
                                <FullUserInfoEditButtonContainer>
                                    <FollowButton
                                        onClick={() =>
                                            dispatch(
                                                setShowDownloadPrompt(true)
                                            )
                                        }
                                    >
                                        <FollowButtonInnerContainer>
                                            <FollowIcon src="/img/primary-follow-icon.svg" />
                                            <FollowLabel>Follow</FollowLabel>
                                        </FollowButtonInnerContainer>
                                    </FollowButton>
                                     <ShareContainer
                                        onClick={() =>
                                            dispatch(
                                                setShowDownloadPrompt(true)
                                            )
                                        }
                                    >
                                        <ShareIcon src="/img/share-icon.svg" />
                                    </ShareContainer>
                                </FullUserInfoEditButtonContainer>
                            )} */}

                        {isLoggedInUser && (
                            <Link href="/edit-profile" prefetch={false}>
                                <a>
                                    <FullUserInfoEditButtonContainer>
                                        <FullUserInfoEditButtonInner>
                                            <FullUserInfoEditButton>
                                                <FullUserInfoEditButtonDetails>
                                                    <FullUserInfoEditButtonImage src="/img/edit-alt.svg" />
                                                    <FullUserInfoEditButtonText>
                                                        Ubah profil
                                                    </FullUserInfoEditButtonText>
                                                </FullUserInfoEditButtonDetails>
                                            </FullUserInfoEditButton>
                                        </FullUserInfoEditButtonInner>
                                    </FullUserInfoEditButtonContainer>
                                </a>
                            </Link>
                        )}
                    </FullUserInfoRightContainer>

                    <FullUserInfoLeftContainer>
                        <FullUserInfoImage>
                            <img
                                alt="user photo"
                                src={compressedUserPhoto(userDetails)}
                                loading="lazy"
                                style={{
                                    minWidth: '100%',
                                    minHeight: '100%',
                                }}
                            />
                        </FullUserInfoImage>
                    </FullUserInfoLeftContainer>
                </FullUserInfoContainer>
                {/* </HeaderContainer> */}

                <TabsContainer ref={navElement} sticky={isSticky}>
                    <ListItems>
                        <Item
                            active={
                                activeProfileTab === PROFILETABTYPES.AKTIFITAS
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                dispatch(
                                    updateProfileTab(PROFILETABTYPES.AKTIFITAS)
                                )
                            }}
                        >
                            <ItemText>Aktifitas</ItemText>
                        </Item>
                        <Item
                            active={
                                activeProfileTab === PROFILETABTYPES.KARYA
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                dispatch(
                                    updateProfileTab(PROFILETABTYPES.KARYA)
                                )
                            }}
                        >
                            <ItemText>Karya</ItemText>
                        </Item>
                    </ListItems>
                </TabsContainer>
                {activeProfileTab === PROFILETABTYPES.KARYA && (
                    <>
                        {userCatalogs && userCatalogs.length > 0 ? (
                            <>
                                <ItemContainer>
                                    {userCatalogs &&
                                        userCatalogs.length &&
                                        userCatalogs.map((catalog) => {
                                            const {
                                                id,
                                                image,
                                                imageMeta,
                                                title,
                                                videoUrl,
                                                type,
                                            } = catalog
                                            return (
                                                <VerticalCardItem
                                                    key={id}
                                                    id={id}
                                                    image={image}
                                                    imageMeta={imageMeta}
                                                    title={title}
                                                    videoUrl={videoUrl}
                                                    type={type}
                                                    category={type}
                                                />
                                            )
                                        })}
                                </ItemContainer>
                                {totalUserCatalogCount > 10 &&
                                    totalUserCatalogCount >
                                        userCatalogs.length && (
                                        <ShowMoreButtonContainer
                                            onClick={() =>
                                                totalUserCatalogCount >
                                                userCatalogs.length
                                                    ? fetchMore()
                                                    : {}
                                            }
                                        >
                                            <ShowMoreButton>
                                                <ShowMoreButtonText>
                                                    TAMPILKAN LEBIH BANYAK
                                                </ShowMoreButtonText>
                                            </ShowMoreButton>
                                        </ShowMoreButtonContainer>
                                    )}
                            </>
                        ) : (
                            <NoData
                                img="/img/no-podcast-empty.svg"
                                title="Belum Ada Karya"
                                msgs={[
                                    'Jadikan hidup lebih berarti dengan karya',
                                    'buatan sendiri. Yuk, mulai.',
                                ]}
                            />
                        )}
                    </>
                )}
                {activeProfileTab === PROFILETABTYPES.AKTIFITAS && (
                    <>
                        {userActivities && userActivities.length > 0 ? (
                            <ActivityContainer>
                                {userActivities &&
                                    userActivities.length &&
                                    userActivities.map((activity) => {
                                        const { item, type } = activity
                                        return (
                                            <>
                                                <ActivityBox key={item.id}>
                                                    <ActivityComment>
                                                        <ActivityCommentHeader>
                                                            <Avatar
                                                                loading="lazy"
                                                                src={compressedUserPhoto(
                                                                    item?.user
                                                                )}
                                                            />
                                                            <CommentInfoContainer>
                                                                <CommentInfoInnerContainer>
                                                                    <CommentInfoNameContainer>
                                                                        <CommentUserName>
                                                                            {
                                                                                item
                                                                                    ?.user
                                                                                    ?.displayName
                                                                            }
                                                                        </CommentUserName>
                                                                        <DisplayUserName>
                                                                            @
                                                                            {
                                                                                item
                                                                                    ?.user
                                                                                    ?.userName
                                                                            }
                                                                        </DisplayUserName>
                                                                    </CommentInfoNameContainer>

                                                                    <DisplaynameAndTimeContainer>
                                                                        {/* <DisplaynameContainer>
                                                                                <DisplayUserName>
                                                                                    @
                                                                                    {
                                                                                        item
                                                                                            ?.user
                                                                                            ?.userName
                                                                                    }
                                                                                </DisplayUserName>
                                                                            </DisplaynameContainer> */}
                                                                        <TimeCointainer>
                                                                            <CommentTime>
                                                                                {timeAgo(
                                                                                    item?.createdAt
                                                                                )}
                                                                            </CommentTime>
                                                                        </TimeCointainer>
                                                                    </DisplaynameAndTimeContainer>
                                                                </CommentInfoInnerContainer>
                                                                <Commentar>
                                                                    {type ===
                                                                        'action' &&
                                                                    item.action ===
                                                                        'follow'
                                                                        ? 'Following:'
                                                                        : 'berkomentar:'}
                                                                </Commentar>
                                                            </CommentInfoContainer>
                                                        </ActivityCommentHeader>
                                                        {item?.message && (
                                                            <div>
                                                                <ActivityCommentByUser>
                                                                    {UnicodeToChar(
                                                                        item?.message
                                                                    )}
                                                                </ActivityCommentByUser>
                                                            </div>
                                                        )}
                                                    </ActivityComment>
                                                    <ContentContainer>
                                                        {type === 'post' &&
                                                            item.entity && (
                                                                <EpisodeInformationPage
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    content={
                                                                        item.entity
                                                                    }
                                                                    catalogName={
                                                                        item
                                                                            ?.entity
                                                                            ?.catalog
                                                                            .title
                                                                    }
                                                                />
                                                            )}
                                                        {type === 'action' && (
                                                            <ContentInformationContainer
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/catalog/${item?.entityId}`
                                                                    )
                                                                }}
                                                            >
                                                                <ContentInformationImage
                                                                    loading="lazy"
                                                                    isNoicebook={
                                                                        item?.entitySubType ===
                                                                        'audiobook'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    src={compressedImage(
                                                                        item
                                                                            ?.entity
                                                                            ?.image,
                                                                        item
                                                                            ?.entity
                                                                            ?.imageMeta,
                                                                        item?.entitySubType ===
                                                                            'audiobook'
                                                                    )}
                                                                />
                                                                <ContentTypeTitleAndFollowers>
                                                                    <ContentTitleText>
                                                                        {
                                                                            item
                                                                                ?.entity
                                                                                ?.title
                                                                        }
                                                                    </ContentTitleText>
                                                                    {item?.entitySubType !==
                                                                        'audiobook' && (
                                                                        <>
                                                                            <div>
                                                                                <ContentFollowersText>
                                                                                    {`

                                ${
                                    (item?.entity?.meta?.aggregations
                                        ?.followers &&
                                        NumberConversion(
                                            item?.entity?.meta?.aggregations
                                                ?.followers
                                        )) ||
                                    0
                                }
                                 Subscribers`}
                                                                                </ContentFollowersText>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </ContentTypeTitleAndFollowers>
                                                                <AnotherFollowButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        dispatch(
                                                                            setShowDownloadPrompt(
                                                                                true
                                                                            )
                                                                        )
                                                                    }}
                                                                >
                                                                    <FollowButtonIcon
                                                                        loading="lazy"
                                                                        src={
                                                                            item?.entitySubType ===
                                                                            'audiobook'
                                                                                ? '/img/save-to-collection.svg'
                                                                                : item?.entitySubType ===
                                                                                  'radio'
                                                                                ? '/img/favourite-icon.svg'
                                                                                : '/img/secondary-follow-icon.svg'
                                                                        }
                                                                    />
                                                                    <FollowButtonText>
                                                                        {item?.entitySubType ===
                                                                        'audiobook'
                                                                            ? 'Simpan'
                                                                            : item?.entitySubType ===
                                                                              'radio'
                                                                            ? 'Favorit'
                                                                            : 'Subscribe'}
                                                                    </FollowButtonText>
                                                                </AnotherFollowButton>
                                                            </ContentInformationContainer>
                                                        )}
                                                    </ContentContainer>
                                                </ActivityBox>
                                            </>
                                        )
                                    })}
                                {totalUserActivitiesCount > 10 &&
                                    totalUserActivitiesCount >
                                        userActivities.length && (
                                        <ShowMoreButtonContainer
                                            onClick={() =>
                                                totalUserActivitiesCount >
                                                userActivities.length
                                                    ? fetchMore()
                                                    : {}
                                            }
                                        >
                                            <ShowMoreButton>
                                                <ShowMoreButtonText>
                                                    TAMPILKAN LEBIH BANYAK
                                                </ShowMoreButtonText>
                                            </ShowMoreButton>
                                        </ShowMoreButtonContainer>
                                    )}
                            </ActivityContainer>
                        ) : (
                            <NoData
                                img="/img/no_activity.svg"
                                title="Belum Ada Aktivitas"
                                msgs={[
                                    'Masih sepi nih…',
                                    'Yuk, mulai dengerin konten favoritmu!',
                                ]}
                            />
                        )}
                    </>
                )}
            </Container>
        </Fragment>
    )
}

export default UserProfileDetails
