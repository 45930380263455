import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    background: #1f1f1f;
`

export const HeaderContainer = styled.div`
    ${ColumnFlex}
    width: 100%;
    background: ${(props) =>
            `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 62.56%),url(${props.imageUrl})`}
        no-repeat;
    background-size: cover;
`

export const PageHeader = styled.div`
    ${RowFlex}
    justify-content: center;
    padding: 20px;
`

export const Header = styled.div`
    ${RowFlex}
    padding: 15px;
    position: fixed;
    top: 0;
    width: 80%;
    background-color: rgba(20, 20, 20, 0.8);
    align-items: center;
    z-index: 999;
    justify-content: space-between;
    align-self: center;
    @media (max-width: 990px) {
        width: 100%;
    }
`
export const BackButton = styled.img`
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
`
export const HeaderText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #fafafa;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const SearchImage = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 3vw;
    cursor: pointer;
`

export const ShareImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
`

export const UserInfoContainer = styled.div`
    // ${ColumnFlex}
    // width: 100%;
    // margin-top: 70px;
    // // height: 20px;
    // padding: 0 4.44%;
    @media (min-width: 905px) {
        display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    padding: 93px 10px 0;
`

export const UserProfileAndNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
`
export const UserProfileImageContainer = styled.div`
    width: 100px;
    height: 100px;
`
export const UserProfileImage = styled.div`
    img {
        border-radius: 50%;
        object-fit: cover;
    }
`

export const UserProfileNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 16px;
    @media (max-width: 410px) {
        padding: 0;
    }
`
export const UserProfileFollowerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;
    gap: 4px;
    @media (max-width: 410px) {
        padding: 10px 10px;
    }
`
export const UserProfileNumber = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
`
export const UserProfileTag = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #ffffff;
`

export const UserInfoAndBioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
`

export const UserInfoDisplayName = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #fafafa;
`
export const UserInfoUserName = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #fafafa;
`

export const UserInfoBio = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #ffffff;
    word-break: break-word;
`

export const UserEditButtonContainer = styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;

    border: 1px solid #ffffff;
    border-radius: 4px;
    width: 100%;
`

export const UserEditButtonInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 46px;
    gap: 10px;
    width: 100%;

    border-radius: 4px;
`

export const UserEditButtonInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
`

export const UserEditButtonImage = styled.img`
    width: 16px;
    height: 16px;
`

export const UserEditButtonText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #ffffff;
`

export const ProfileHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px 10px 0;

    // position: absolute;
    // width: 100%;
    // height: 100px;
`

export const UserImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    position: static;
    width: 100px;
    height: 100px;
    left: 0px;
    top: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
`
export const UserImage = styled.img`
    border-radius: 50%;
    width: 100px;
    height: 100px;
`
export const UserInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    position: static;
    // width: 228px;
    height: 92px;
    left: 100px;
    top: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
`

export const UserInfoBoxFollowers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    position: static;
    // width: 228px;
    height: 43px;
    left: 0px;
    top: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 13px 0px;
    justify-content: space-around;
`

export const FollowersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;

    position: static;
    width: 80px;
    height: 43px;
    left: 0px;
    top: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 0px;
`

export const FollowerLabel = styled.div`
    position: static;
    width: 47px;
    height: 12px;
    left: 14.5px;
    top: 27px;

    /* Overline */

    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 4px 0px;
`
export const FollowerNumber = styled.div`
    position: static;
    width: 70px;
    height: 19px;
    left: 24.5px;
    top: 4px;

    /* Body 1 */

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px;
`

export const FollowButtonAndShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    position: static;
    width: 191px;
    height: 36px;
    left: 18.5px;
    top: 56px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 13px 0px;
`

export const FollowButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    background: #fad810;
    border-radius: 4px;

    /* Inside auto layout */

    cursor: pointer;
`
export const FollowButtonInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: static;
    width: 83px;
    height: 17px;
    left: 24px;
    top: 9px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
`

export const FollowIcon = styled.img`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: static;
    // width: 83px;
    height: 17px;
    left: 24px;
    top: 9px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
`
export const FollowLabel = styled.div`
    position: static;
    width: 59px;
    left: calc(50% - 59px / 2 + 12px);
    top: 0%;
    bottom: 0%;

    /* Font/Button */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #1e222c;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
`
export const ShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;

    position: static;
    width: 48px;
    height: 36px;
    left: 143px;
    top: 0px;

    border: 1px solid #ffffff;
    backdrop-filter: blur(81.5485px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 12px;

    cursor: pointer;
`
export const ShareIcon = styled.img`
    position: static;
    width: 16px;
    height: 16px;
    left: 16px;
    top: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
`

export const UserDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 0px 0px 0px 16px;

    // position: absolute;
    // width: 360px;
    // height: 80px;
    // left: 0px;
    // top: 200px;
`

export const UsernameAndVerifiedContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: static;
    width: 125px;
    height: 20px;
    left: 16px;
    top: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
`

export const Username = styled.div`
    position: static;
    // width: 105px;
    height: 20px;
    left: 0px;
    top: 0px;

    /* Font / Subtitle 2 */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #fafafa;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
`
export const VerifyBadge = styled.img`
    position: static;
    width: 16px;
    height: 16px;
    left: 109px;
    top: 2px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
`
export const DisplayName = styled.div`
    position: static;
    width: 344px;
    height: 20px;
    left: 16px;
    top: 24px;

    /* Font / Body 2 */

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #fafafa;

    /* Inside auto layout */

    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
`
export const UserDescription = styled.div`
    position: static;
    width: 344px;
    height: 32px;
    left: 16px;
    top: 48px;

    /* Body 2 */

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
`

export const TabsContainer = styled.div`
    ${ColumnFlex}
    // position: ${({ sticky }) => (sticky ? 'fixed' : 'relative')};
    top: ${({ sticky }) => (sticky ? '50px' : '0')};
    width: 100%;
    z-index: 4;
    // max-width: 698px;

    background: #000000;
`

export const ListItems = styled.ul`
    ${RowFlex}
    padding: 0 4.44%;
    margin: 0;
    justify-content: flex-start;
    list-style-type: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const Item = styled.li`
    color: ${(props) => (props.active ? '#ffffff' : '#888888')};
    cursor: pointer;
    font-size: 14px;
    padding: 10px 2px;
    position: relative;
    margin-right: 10px;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        bottom: -1px;
        background-color: ${(props) =>
            props.active ? '#fad810' : 'transparent'};
    }
`

export const ItemText = styled.h2`
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0px;
`

export const ItemContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    grid-gap: 15px;
    justify-content: space-around;
    padding: 0 20px;
    width: 100%;
    margin-top: 5%;

    @media (min-width: 600px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 905px) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(6, 1fr);
    }
`

export const ShowMoreButtonContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    width: 100%;
    height: 7.5vh;
    margin-top: 2.5vh;
    cursor: pointer;
    margin-bottom: 50%;
    @media (min-width: 760px) {
        margin-bottom: 20%;
    }
`
export const ShowMoreButton = styled.div`
    ${RowFlex}
    ${FlexCentered}
width: 100%;
    height: 7.5vh;
    box-sizing: border-box;
    width: 91.92%;

    border: 1px solid #fad810;
    border-radius: 4px;
`
export const ShowMoreButtonText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #fad810;
`
export const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    // width: 360px;
    // height: 2072px;

    /* Inside auto layout */

    flex: none;
    order: 4;
    flex-grow: 0;
    margin-top: 5%;
    // margin-bottom: 50%;
    @media (max-width: 990px) {
        margin-bottom: 20%;
    }
`
export const ActivityBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 16px;
    width: 100%;
    margin-bottom: 20px;

    // position: absolute;
    // width: 360px;
    // height: 332px;
    // left: 0px;
    // top: 348px;
`
export const ActivityComment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;

    // width: 328px;
    // height: 116px;

    /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const ActivityCommentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;

    // width: 328px;
    // height: 48px;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const Avatar = styled.img`
    width: 40px;
    height: 40px;

    border-radius: 100px;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const CommentInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 15px;
    gap: 4px;
    width: 100%;

    // width: 272px;
    // height: 41px;

    // /* Inside auto layout */

    // flex: none;
    // order: 1;
    // flex-grow: 0;
`
export const CommentInfoInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 100%;

    // width: 272px;
    // height: 20px;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const CommentInfoNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 4px;

    // width: 272px;
    // height: 20px;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const CommentUserName = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #ffffff;
`
export const DisplaynameAndTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 96px;

    // width: 193px;
    // height: 12px;

    // /* Inside auto layout */

    // flex: none;
    // order: 1;
    // flex-grow: 0;
`
export const DisplaynameContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    // width: 46px;
    // height: 12px;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const DisplayUserName = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    color: rgba(255, 255, 255, 0.7);
`

export const TimeCointainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    // // width: 45px;
    // height: 12px;

    // /* Inside auto layout */

    // flex: none;
    // order: 1;
    // flex-grow: 0;
`
export const CommentTime = styled.div`
    // width: 45px;
    // height: 12px;

    /* Overline/Regular/Title Case */

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */

    letter-spacing: 0.015em;

    /* Medium Grey/100 */

    color: #a3a3a3;

    // /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;
`
export const Commentar = styled.div`
    /* Body/Body 4/Regular */

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    letter-spacing: 0.004em;

    /* White/300 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const ActivityCommentByUser = styled.div`
    /* Body/Body 3/Regular */

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    letter-spacing: 0.0025em;

    /* White/300 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ContentInformationContainer = styled.div`
    background: #202020;
    border-radius: 8px;
    width: 100%;
    height: 10vh;
    margin-top: 2.21vh;
    ${RowFlex}
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
`

export const ContentInformationImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    ${({ isNoicebook }) =>
        isNoicebook &&
        css`
            height: 40px;
            width: 30px;
        `}
`

export const ContentTypeTitleAndFollowers = styled.div`
    ${ColumnFlex}
    justify-content: center;
    align-items: flex-start;
    width: 48%;
    height: 100%;
`

export const ContentTypeText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 140%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #fad810;
`

export const ContentTitleText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const ContentFollowersText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #707070;
`

export const AnotherFollowButton = styled.div`
    // width: 22%;
    // height: 3.28vh;
    ${RowFlex}
    ${FlexCentered}
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px 8px;
`

export const FollowButtonIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 5px;
`

export const FollowButtonText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #ffffff;
`

export const FullUserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 93px 30px 0px;
    gap: 20px;
    width: 100%;
    background: ${(props) =>
            `linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, #000000 62.56%),url(${props.imageUrl})`}
        no-repeat;
    background-size: cover;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, #000000 62.56%);
    @media (max-width: 904px) {
        display: none;
    }
    background-position: center;
`

export const FullUserInfoInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 4.44%;
`
export const FullUserInfoRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 80%;
`

export const FullUserNameAndDisplayNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
`

export const FullUserName = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    /* Medium Grey/500 */

    color: #7a7a7a;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
`

export const FullDisplayName = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 120%;
    /* identical to box height, or 41px */

    letter-spacing: 0.0025em;

    color: #fafafa;
    margin-bottom: 0px;
`

export const FullUserInfoNumberContainer = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    margin-bottom: 0px;
`
export const FullUserInfoFollowerContainer = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
`
export const FullUserInfoFollowersInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 4px;
`
export const FullUserInfoNumberText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    text-align: center;
    letter-spacing: 0.0075em;

    color: #ffffff;
`

export const FullUserInfoTagText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    text-align: center;
    letter-spacing: 0.0125em;
    text-transform: capitalize;

    /* Medium Grey/500 */

    color: #7a7a7a;
    cursor: ${(props) => (props.text == 'Mendengar' ? '' : 'pointer')};
`

export const FullUserInfoBioContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    @media (min-width: 905px) {
        width: 620px;
    }
    @media (min-width: 990px) {
        width: 520px;
    }
    @media (min-width: 1120px) {
        width: 620px;
    }
    @media (min-width: 1280px) {
        width: 749px;
    }
    @media (min-width: 1440px) {
        width: 720px;
    }
`

export const FullUserInfoBio = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    word-break: break-word;
    /* or 20px */

    letter-spacing: 0.01em;

    /* White/100 */

    color: #ffffff;
    padding: 0px;
    margin: 0px;
`

export const FullUserInfoEditButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    cursor: pointer;
`

export const FullUserInfoEditButtonInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    border: 1px solid #ffffff;
    border-radius: 4px;
`

export const FullUserInfoEditButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 46px;
    gap: 10px;

    border-radius: 4px;
`

export const FullUserInfoEditButtonDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
`

export const FullUserInfoEditButtonImage = styled.img`
    width: 16px;
    height: 16px;
`

export const FullUserInfoEditButtonText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #ffffff;
`

export const FullUserInfoLeftContainer = styled.div`
    width: 200px;
    height: 200px;
`
export const FullUserInfoImage = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
    img {
        border-radius: 50%;
        object-fit: cover;
    }
`

export const Langganan = styled.a`
    display: flex;
    gap: 10px;
    height: 45px;
    background: #161616;
    border-radius: 8px;
    padding: 25px 16px;
    width: 100%;
    align-items: center;

    span {
        color: var(--White, var(--neutral-neutral-99, #fafafa));
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        letter-spacing: 0.1px;
    }
`

export const Voucher = styled.a`
    display: flex;
    gap: 10px;
    height: 45px;
    background: #161616;
    border-radius: 8px;
    padding: 25px 16px;
    width: 100%;
    align-items: center;

    span {
        color: var(--White, var(--neutral-neutral-99, #fafafa));
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        letter-spacing: 0.1px;
    }
`
