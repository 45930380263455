import {
    CardContainer,
    CardContent,
    CardImage,
    CardTitle,
    CardTime,
    CardSubTitle,
    AudioBookCardImageContainer,
} from './styles'
import compressedImage from '@/helpers/compressedImage'
import NumberConversion from '@/helpers/numberConversion'
import SecondsToTime from '@/helpers/secondsToTime'
import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'

const VerticalCardItem = ({
    id,
    image,
    imageMeta,
    title,
    subTitle,
    videoUrl,
    entityType,
    duration,
    page,
    type,
    category,
    contentCount,
    viewType,
    badge,
}) => {
    const imageElement = useRef(false)
    const [imageHeight, setImageHeight] = useState('auto')

    const slicedTitle = (title) =>
        title && title.length > 55 ? title.slice(0, 55) + '...' : title
    let path
    if (entityType) {
        path = entityType
    } else if (type) {
        if (
            type === 'podcast' ||
            type === 'audiobook' ||
            type === 'radio' ||
            type === 'music' ||
            type === 'audioseries'
        ) {
            path = 'catalog'
        }
    }

    useEffect(() => {
        viewType !== 'audiobook' &&
            setImageHeight(imageElement.current.offsetWidth)
    }, [viewType])

    return (
        <Link href={`/${path}/${id}`} prefetch={false}>
            <a>
                <CardContainer ref={imageElement}>
                    {viewType === 'audiobook' || category !== 'audiobook' ? (
                        <div style={{ position: 'relative' }}>
                            <CardImage
                                loading="lazy"
                                src={compressedImage(
                                    image,
                                    imageMeta,
                                    viewType === 'audiobook'
                                )}
                                alt={title}
                                height={imageHeight}
                            />
                            {badge === 'vip' && (
                                <img
                                    src="/img/vip.svg"
                                    alt="vip badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        height: '16px',
                                    }}
                                />
                            )}
                            {badge === 'earlyAccess' && (
                                <img
                                    src="/img/early_access_new.svg"
                                    alt="early access badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        height: '16px',
                                    }}
                                />
                            )}
                            {badge === 'premium' && (
                                <img
                                    src="/img/premium.svg"
                                    alt="premium badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        height: '16px',
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <AudioBookCardImageContainer
                            height={imageHeight}
                            background={image}
                        >
                            <div
                                height={imageHeight}
                                style={{
                                    width: '100%',
                                    height: imageHeight,
                                    backdropFilter: 'blur(4px)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CardImage
                                    loading="lazy"
                                    src={
                                        image ||
                                        '/img/dummy-content-or-catalog-image.svg'
                                    }
                                    alt={title}
                                    style={{ height: '90%', width: 'auto' }}
                                />

                                {badge === 'vip' && (
                                    <img
                                        src="/img/vip.svg"
                                        alt="vip badge"
                                        style={{
                                            position: 'absolute',
                                            bottom: '4px',
                                            left: '4px',
                                            borderRadius: '0px',
                                            height: '16px',
                                        }}
                                    />
                                )}
                                {badge === 'earlyAccess' && (
                                    <img
                                        src="/img/early_access_new.svg"
                                        alt="early access badge"
                                        style={{
                                            position: 'absolute',
                                            bottom: '4px',
                                            left: '4px',
                                            borderRadius: '0px',
                                            height: '16px',
                                        }}
                                    />
                                )}
                                {badge === 'premium' && (
                                    <img
                                        src="/img/premium.svg"
                                        alt="premium badge"
                                        style={{
                                            position: 'absolute',
                                            bottom: '4px',
                                            left: '4px',
                                            borderRadius: '0px',
                                            height: '16px',
                                        }}
                                    />
                                )}
                            </div>
                        </AudioBookCardImageContainer>
                    )}
                    <CardContent>
                        <CardTitle href={`/${path}/${id}`}>
                            {videoUrl && (
                                <img
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '6px',
                                        marginTop: '-2px',
                                    }}
                                    alt="video icon"
                                    src="/img/video_icon.svg"
                                />
                            )}

                            {slicedTitle(title) || slicedTitle(subTitle)}
                        </CardTitle>

                        {page === 'audiobook' ||
                            (page === 'home' && (
                                <>
                                    {' '}
                                    <CardSubTitle>
                                        {slicedTitle(subTitle)}
                                    </CardSubTitle>
                                    {duration && (
                                        <CardTime>
                                            {duration < 60
                                                ? duration + ' s'
                                                : SecondsToTime(duration)}
                                        </CardTime>
                                    )}
                                </>
                            ))}
                        {contentCount && (
                            <CardSubTitle>{`${NumberConversion(contentCount)} ${
                                contentCount > 1 ? 'Episodes' : 'Episode'
                            }`}</CardSubTitle>
                        )}
                    </CardContent>
                </CardContainer>
            </a>
        </Link>
    )
}

export default VerticalCardItem
